<template>
	<div id="calendar-page" v-if="calendarInfoIsLoaded">
		<router-view></router-view>
	</div>
</template>

<script>
import Calendar from '../scripts/calendar';
import { PRIVATE } from '../scripts/visibilityLevels';
import CMS from '../service/cms/service';

const CALENDAR_NOT_FOUND = 1
const CALENDAR_RESTRICTED = 2
const CALENDAR_FORBIDDEN = 2

export default {
	name: 'CalendarPage',
	watch: {
        "$route.params.calendarUid": {
            handler: function (calendarUid) {
				this.calendarUpdateHandler(calendarUid)
            },
            deep: true,
            immediate: true,
        },
	},

	computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},
		calendar(){
			if (!this.calendarUid)
				return null
			return this.$store.getters.getCalendarByUid(this.calendarUid)
		},
	},

	data() {
		return {
			calendarInfoIsLoaded: true,
			calendarStatus: 0,
		};
	},

	created() {
		this.calendarUpdateHandler(this.calendarUid)
	},

	methods: {

		redirectToErrorPage(errorType) {
			let errorPageName = null

			if (errorType == CALENDAR_NOT_FOUND)
				errorPageName = "calendar-error-not-found"
			else if (errorType == CALENDAR_RESTRICTED)
				errorPageName = "calendar-error-restricted"

			if (!errorPageName)
				return

			if (this.$route.name == errorPageName)
				return

			if (this.calendar && this.calendar.isRestricted) {
				if (this.calendar.suggestionAction == 'authorize'
				&& this.calendar.visibilityLevelId != PRIVATE) {
					localStorage.setItem('originPage-auth', JSON.stringify({
						name: this.$route.name,
						params: this.$route.params,
						query: this.$route.query
					}))
				}
			}

			this.$router.replace({
				name: errorPageName,
				params: {
					calendarUid: this.calendarUid
				}
			})
		},

		calendarUpdateHandler(calendarUid){
			if (!calendarUid)
				return
			const calendar = this.$store.getters.getCalendarByUid(calendarUid)
			this.$store.dispatch("set-current-calendar", calendarUid)
			if (!calendar && !this.isCalendarErrorPage()) {
				this.setupCalendarInfo(calendarUid)
			}
		},

		isCalendarErrorPage(){
			return this.$route.name == "calendar-error-not-found" 
				|| this.$route.name == "calendar-error-restricted"
		},

		setupCalendarInfo(calendarUid){
			this.calendarStatus = 0
			if (!this.calendarInfoIsLoaded){
				return
			}
			this.calendarInfoIsLoaded = false
			return CMS.calendar
                .getByUid(calendarUid)
                .then((data) => {
                    if (data.err) 
                        throw data.err

                    const calendarData = data["allowed"] ?? data["restricted"]
					if (!calendarData)
						return ;
					
					const isRestricted = !!data.restricted
					const calendarInstance = new Calendar(calendarData, isRestricted)
					this.$store.dispatch("add-calendar", calendarInstance);
					if (isRestricted) {
						this.redirectToErrorPage(CALENDAR_RESTRICTED)
					}
                    if (calendarData.isRestricted || !calendarInstance.isEditable)
						return;
					
					this.$store.dispatch("setup-slot-templates")
				})
				.catch((err) => {
					if (!err || !err.response)
						return 
					if (err.response.status == 403) {
						this.redirectToErrorPage(CALENDAR_FORBIDDEN)
					} else if (err.response.status == 400) {
						this.redirectToErrorPage(CALENDAR_NOT_FOUND)
					}
				})
                .finally(() => {
					this.calendarInfoIsLoaded = true
                })
		},
	}
};
</script>

<style scoped>
#calendar-page{
    height: inherit;
}
</style>